@import "assets/scss/variables";

.modal_customer{
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.7);
  padding: 20px;
}
.modal_customer_body{
  position: relative;
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  border-radius: 10px;
  padding: 30px;


}
.modal_customer_close{
  font-family: "Lato-Regular", serif;
  position: absolute;
  top: 28px;
  right: 28px;
}
.modal_customer_close img{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.modal_text_md{
  font-family: "Gotham-Bold", serif;
  word-wrap: normal;
  font-size: 24px;
  color: $meralco-darkblue;
  white-space: pre;
}
.modal_text_sm{
  //added fsbv
  font-family: "Lato-Regular" , serif;
  text-align: center;
  // changed 16 from 18px
  font-size: 16px;
}

@media screen and (max-width: 1000px){
  .modal_customer{
    padding: 10px;
  }
  .modal_customer_body{
    width: 100%;
    padding: 10px;
  }
  .modal_text_md{
    font-size: 18px;
  }
  .modal_text_sm{
    font-size: 15px;
  }



}
