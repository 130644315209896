/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "Helvetica Neue";
    src: url("./assets/fonts/HelveticaNeue.otf") format("opentype");
}
@font-face {
    font-family: "Helvetica Neue Medium";
    src: url("./assets/fonts/HelveticaNeue-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Helvetica Light";
    src: url("./assets/fonts/HelveticaNeue-Light.otf") format("opentype");
}
@font-face {
    font-family: "Helvetica Neue Bold";
    src: url("./assets/fonts/HelveticaNeue-Bold.otf") format("opentype");
}
@font-face {
    font-family: "TradeGothic LT Bold";
    src: url("./assets/fonts/TradeGothicLTBold.ttf") format("truetype");
}
@font-face {
    font-family: "Gotham-Bold";
    src: url("./assets/fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Gotham-Medium";
    src: url("./assets/fonts/Gotham-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Gotham-Light";
    src: url("./assets/fonts/Gotham-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Gotham-Book";
    src: url("./assets/fonts/Gotham-Book.otf") format("opentype");
}
@font-face {
    font-family: "Lato-Bold";
    src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Lato-Light";
    src: url("./assets/fonts/Lato-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Lato-Medium";
    src: url("./assets/fonts/Lato-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Lato-Regular";
    src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Calibri";
    src: url("./assets/fonts/Calibri-Regular.ttf") format("truetype");
}




body{
    font-family: 'Gotham-Book', sans-serif;
}
.page-content{
    min-height: 90vh;
    padding: 80px 139px 0px 139px;
}
.row{
    padding: 0px !important;
    margin: 0px !important;
}
@media screen and (max-width: 1000px){
    .page-content{
        min-height: 90vh;
        padding: 80px 20px 0px 20px;
        width: 100%;
    }
}

html, body { height: 100%; }
body { margin: 0; }


