$meralco-blue : #171C8F;
$meralco-skyblue : #00b7bd;
$meralco-skyblue-20 : #CCF1F2;
$meralco-black : #666666;
$meralco-black-80 : #333333;
$meralco-gray: #9A9B9C;
$meralco-darkblue: #284D76;

$common-gray: #404040;
$meralco-gray-20 : #EEEEEE;
$meralco-gray-40 : #BCBCBD;
$meralco-gray-60 : #9A9B9C;
$meralco-gray-80 : #333333;
$meralco-gray-100 : #58595B;

$meralco-green: #84BD00;
$meralco-yellow: #F2D052;

$meralco-form-gray: #333333;
$meralco-form-outline: #7376BC;
$meralco-cerlse: #EF426F ;

$box-shadow: #171C8F26;
$dark-blue: #284D76;
$light-blue: #5F96BA;
$lighter-blue: #9FC0D5;

$meralco-yellow: #F2D052;

@keyframes ldio-okxz8jkbhq-1 {
  0% { top: 12.5px; height: 75px }
  50% { top: 25px; height: 50px }
  100% { top: 25px; height: 50px }
}
@keyframes ldio-okxz8jkbhq-2 {
  0% { top: 15.625px; height: 68.75px }
  50% { top: 25px; height: 50px }
  100% { top: 25px; height: 50px }
}
@keyframes ldio-okxz8jkbhq-3 {
  0% { top: 18.75px; height: 62.5px }
  50% { top: 25px; height: 50px }
  100% { top: 25px; height: 50px }
}

.ldio-okxz8jkbhq div { position: absolute; width: 12px }.ldio-okxz8jkbhq div:nth-child(1) {
                                                          left: 19px;
                                                          background: #f2d155;
                                                          animation: ldio-okxz8jkbhq-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
                                                          animation-delay: -0.2s
                                                        }
.ldio-okxz8jkbhq div:nth-child(2) {
  left: 44px;
  background: #f2d155;
  animation: ldio-okxz8jkbhq-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.1s
}
.ldio-okxz8jkbhq div:nth-child(3) {
  left: 69px;
  background: #f2d155;
  animation: ldio-okxz8jkbhq-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: undefineds
}

.loadingio-spinner-pulse-uq8kufhdmv {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-okxz8jkbhq {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-okxz8jkbhq div { box-sizing: content-box; }

._tooltip{
  display: none;
  position: absolute;
  margin-top: 14px;
  font-family: "Helvetica Neue", serif;
  font-size: 13px;
  background-color: $meralco-skyblue;
  padding: 7px;
  color: #FFF;
}


